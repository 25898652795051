.simple {
    .header {
        background-color: $primary;

        &-logo {
            transform-origin: left;
            transition: transform $transition-length ease-in-out;
        }

        &.scrolled {
            .header-logo {
                transform: scale(0.8);
            }

            #main-nav .nav-link {
                --bs-nav-link-padding-y: #{map-get($map: $spacers, $key: md)};
            }
        }
    }
}
