.gallery-text {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    &--right {
        grid-template-areas: 'media media media media media media . content content content content content';
    }

    &--left {
        grid-template-areas: 'content content content content content . media media media media media media';
    }

    &-media {
        grid-area: media;
    }

    &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-row: 1;
        grid-area: content;
        @include spacing(padding, y, map-get($map: $spacers, $key: 3x));

        .text {
            flex: 1;
            @include use-body(sm, $black);
        }

        .link {
            margin-top: map-get($map: $spacers, $key: 2x);
        }
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
    }
}
