.category-filter {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 1fr;
    row-gap: 0.5rem;

    &-wrapper {
        grid-column: 1 / -1;
        @include rfs(1.5rem, gap);
        display: grid;
        grid-template-columns: subgrid;

        &:first-of-type {
            grid-row: 1;
        }
    }

    &-name {
        font-weight: 700;
        grid-column: 1;
        margin-bottom: 0;
    }

    &-list {
        grid-column: 2;
        display: flex;
        flex-wrap: wrap;
        column-gap: map-get($map: $spacers, $key: 'md');
        row-gap: 0.3rem;
        margin-bottom: 0;
        list-style: none;
        padding: 0;

        &-item {
            &-link {
                color: $secondary;
                transition: color $transition-length ease-in-out;

                &:hover,
                &.active {
                    color: $primary;
                }
            }

            &-active {
                font-weight: $font-weight-semibold;
            }
        }

        &-item:not(:last-child) {
            position: relative;
            &:after {
                content: '';
                cursor: pointer;
                position: absolute;
                right: -10px;
                height: 100%;
                background-color: $gray-200;
                width: 2px;
            }
        }
    }

    &-reset {
        grid-row: 1;
        grid-column: 3;
        white-space: nowrap;
    }

    @include media-breakpoint-down(lg) {
        &-reset {
            grid-row: auto;
            grid-column: auto;
            justify-self: flex-end;
            grid-column: 2;
        }
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        gap: 1.5rem;

        &-wrapper {
            gap: 0.5rem;
        }

        &-reset {
            grid-column: auto;
        }
    }
}
