@mixin hero-base($variant: 'default') {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
    background: linear-gradient(180deg, #d9d9d9, #aaa 90.5%);

    .hero-#{$variant}-content {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        color: $white;

        .headline .headline-content {
            margin-bottom: 0;
        }
    }

    @content;
}
