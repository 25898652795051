.hero-simple {
    @include hero-base(simple) {
        &-content {
            justify-content: flex-end;
            height: clamp(200px, 40vh, 350vh);
            margin-bottom: 0;
            @include rfs(4.375rem, padding-bottom);

            .headline {
                .headline-content {
                    @include use-hero(lg, $white);
                }
            }
        }
    }
}
