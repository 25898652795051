.hero-default {
    $image-height: 750px;

    @include hero-base(default) {
        height: 100vh;
        min-height: 920px;
        max-height: 1110px;
        position: relative;
        @include rfs(8rem, padding-top);

        &-content {
            position: relative;
            align-self: flex-end;

            .container {
                display: flex;
                gap: map-get($map: $spacers, $key: 2x);
            }

            .headline {
                @include margin-bottom(50px);

                &-content {
                    @include use-hero(lg, $white);
                    font-weight: 400;
                }

                &-accent {
                    text-transform: none;
                }
            }

            .text {
                @include use-hero(sm, $white);
                @include margin-bottom(30px);
                font-weight: 400;
                font-style: italic;
            }

            .author {
                @include use-hero(sm, $white);
                @include margin-bottom(20px);
                text-align: end;
            }

            .signing-image {
                @include use-hero(sm, $white);
                text-align: end;
            }

            &-image {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                img {
                    z-index: 999;
                    position: relative;
                    object-fit: contain;
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        height: 70vh;
        min-height: 490px;
        max-height: 600px;
        padding-bottom: 75px;

        &-content {
            .headline {
                width: auto;
            }

            &-text {
                padding-top: 0;
            }
        }

        &::after {
            display: none;
        }
    }
}
