.logo-gallery {
    &-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-auto-rows: 1fr;
        gap: $grid-gutter-width;
    }

    &-item {
        background-color: $primary;
        color: $white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        @include rfs(3.5rem, padding);

        &-more {
            color: $white;
            position: absolute;
            left: 50%;
            bottom: 0.75rem;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 14px;
        }

        a {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        img {
            filter: brightness(0) invert(1);
        }
    }
}
