.headline {
    &-accent {
        font-weight: 700;
    }

    &-content {
        &.sm {
            @include use-headline(sm);
        }

        &.md {
            @include use-headline(md);
        }

        &.lg {
            @include use-headline(lg);
        }

        &.section {
            margin-bottom: map-get($map: $spacers, $key: 2x);
            @include use-section(md, $headline-color);
        }
    }
}
