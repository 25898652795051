.card {
    border: none;
    border-radius: 0;

    img {
        width: 100%;
    }

    .card-body {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr max-content max-content;

        .headline-content {
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include media-breakpoint-down(sm) {
                line-height: $line-height-base;
                height: calc(2 * 24px);
                min-height: calc(2 * 24px);
                white-space: wrap;
            }
        }

        .link {
            .btn {
                text-align: start;
                @include rfs($font-size-sm, margin-top);
                color: $white;
            }
        }

        .card-text {
            line-height: $line-height-base;
            height: calc(3 * 24px);
            min-height: calc(3 * 24px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
