.item-banner {
    &-item {
        color: $white;
        display: flex;
        flex-direction: column;
        @include rfs(0.5rem, gap);

        &-headline {
            @include use-hero(lg, $white);
            font-weight: 700;
        }

        &-subheadline {
            @include use-headline(md, $white);
            font-weight: 600;
        }

        &-text {
            @include use-body(sm, $white);
            font-weight: 400;
        }
    }
}
