textarea.form-control {
    min-height: 7.5rem;
}

.form-select,
.form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-inline: 0;
    padding-block: 0.25rem;
    background-color: transparent;
    line-height: 1;

    &:focus {
        box-shadow: none;
        background-color: transparent;

        &::placeholder {
            color: transparent;
        }
    }

    &::placeholder {
        font-weight: 400;
        font-size: 14px;
    }
}

.form-select {
    color: $input-placeholder-color;

    option:not(:first-of-type) {
        color: $body-color;
    }

    option[value=''][disabled] {
        display: none;
    }
}

.form-control {
    border-color: $body-color;
}

.form-label {
    font-family: Montserrat;
    @include use-body(sm);
    margin-bottom: 0;
}

input[type='file'] {
    border: 0;
    margin-top: map-get($map: $spacers, $key: md);

    &::before {
        content: '\e09a';
        font-family: 'Font Awesome 5 Pro';
        padding-right: 2rem;
    }

    &::file-selector-button {
        padding-inline: map-get($map: $spacers, $key: lg);
        display: none;

        &::before {
            content: '\e09a';
            font-family: 'Font Awesome 5 Pro';
        }
    }
}

.accept-privacy {
    margin-top: 0.3em;

    &-text {
        @include use-body(sm);
    }
}

// Cookie Consent Styles
form {
    position: relative;

    &.recaptcha-visible::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
    }

    .cmppreviewroot {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        justify-content: center;
    }
}
