.offcanvas {
    --bs-offcanvas-bg: #{$black};
    --bs-offcanvas-color: #{$white};

    max-width: 70vw;

    &-body {
        display: flex;
        flex-grow: 0;
        justify-self: flex-end;
    }

    // @include media-breakpoint-up(xl) {
    //     --bs-offcanvas-color: #{$primary};
    // }
}
