#main-nav {
    @include rfs(3.125rem, padding-top);
    transition: padding $transition-length ease-in-out;

    .container-inner {
        flex: 1;
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;
    }

    &.navbar {
        --bs-navbar-padding-y: 0;
    }

    .navbar-toggler {
        border-radius: 0;
        border: 0;
        outline: 0;
        line-height: normal;
    }

    .nav-link {
        --bs-navbar-nav-link-padding-x: #{map-get($map: $spacers, $key: md)};
        --bs-nav-link-padding-y: #{map-get($map: $spacers, $key: md)};
        transition: padding $transition-length ease-in-out;

        &[target='_blank'] {
            position: relative;

            &:after {
                content: '\f08e';
                position: absolute;
                font-size: 12px;
                top: 11px;
                right: 0;
                font-family: 'Font Awesome 6 Pro';
                padding-left: 0.5em;
                opacity: 0;
                // transition: opacity $transition-length ease-in-out;
                // transform: rotate(45deg);
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .nav-link,
    .dropdown-link {
        @include use-nav-link(md, $white);
        &:hover {
            text-decoration: underline;
            text-underline-offset: 0.5em;
            text-decoration-thickness: 0.1em;
        }

        &.active {
            text-decoration: underline;
            text-underline-offset: 0.5em;
            text-decoration-thickness: 0.1em;
        }
    }

    .dropdown-menu {
        background-color: transparent;
        transition: background-color $transition-length ease-in-out;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-item {
        --bs-dropdown-link-active-bg: transparent;
    }

    .dropdown-menu.show-mobile {
        @include media-breakpoint-down(md) {
            display: block !important;
        }
    }

    @include media-breakpoint-down(xl) {
        .nav-link {
            padding-bottom: 0;
            text-align: right;
        }
    }
}
