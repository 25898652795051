.icon-banner {
    &-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include rfs(6rem, gap);

        img {
            align-self: flex-end;
            filter: brightness(0);
            max-width: 140px;
        }
    }

    @include media-breakpoint-down(md) {
        .container {
            padding-right: 0;
        }

        &-icons {
            overflow: scroll;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }
}
