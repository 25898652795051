.swiper-container {
    position: relative;

    &:hover {
        .swiper-button {
            opacity: 1;
        }
    }
}

.swiper {
    overflow: visible;

    .swiper-slide {
        height: auto;
        display: flex;
        cursor: grab;
    }
}

.swiper-button-container {
    display: flex;
    justify-content: flex-end;
    @include rfs(2rem, gap);
    @include rfs(2rem, padding-top);
}

.swiper-button {
    cursor: pointer;
    z-index: 2;
    transition: opacity $transition-length ease-in-out;

    &.swiper-button-disabled {
        cursor: default;

        i {
            color: $gray-700;
        }
    }

    i {
        color: $black;
        @include font-size(2rem);
    }
}
