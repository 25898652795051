.accordion {
    display: grid;

    &-item {
        border: 0;
        border-bottom: 1px solid $gray-200;
        align-self: flex-start;
        background: transparent;

        &.no-text {
            .accordion-button {
                cursor: default;

                &::after {
                    display: none;
                }
            }
        }
    }

    &-button {
        @include use-headline(sm);
        box-shadow: none;
        background: transparent;
        display: block;
        padding-right: 3rem;

        &[data-bs-toggle='collapse'] {
            color: $black;
        }

        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    @include media-breakpoint-down(md) {
        &-container {
            &::before {
                display: none;
            }
        }
    }
}
