.page-section {
    @include spacing(padding, y, map-get($map: $spacers, $key: section));

    &.bg-primary,
    &.bg-black {
        .headline-content,
        .text-content {
            color: $white;
        }
    }

    &.bg-gray {
        background-color: $gray-100;
    }

    &.bg-black {
        background-color: $body-color;
    }
}
