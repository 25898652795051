.image-gallery {
    &--big {
        .image-gallery-content {
            height: clamp(920px, 100vh, 1100px);
        }
    }

    &--small {
        .image-gallery-content {
            height: 700px;
        }
    }

    &-content {
        @include rfs(1.5rem, gap);
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-auto-rows: 1fr;

        .image-gallery-item,
        .image-gallery-item-image,
        .image-gallery-item-image .image,
        .image-gallery-item-image .image img {
            height: 100%;
        }

        &.layout_1 {
            grid-template-areas:
                'A1 A1 A1 A1 A1 A1 . . . . . .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 .'
                '. A3 A3 A3 A3 A3 A4 A4 A4 A4 A4 A4'
                '. A3 A3 A3 A3 A3 A4 A4 A4 A4 A4 A4'
                '. A3 A3 A3 A3 A3 A4 A4 A4 A4 A4 A4'
                '. A3 A3 A3 A3 A3 A4 A4 A4 A4 A4 A4'
                '. A3 A3 A3 A3 A3 A4 A4 A4 A4 A4 A4'
                '. . . . . . A4 A4 A4 A4 A4 A4';
        }

        &.layout_2 {
            grid-template-areas:
                'A1 A1 A1 A1 A1 A1 . . . . . .'
                'A1 A1 A1 A1 A1 A1 . . . . . .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                '. A3 A3 A3 A3 A3 A2 A2 A2 A2 A2 A2'
                '. A3 A3 A3 A3 A3 A2 A2 A2 A2 A2 A2'
                '. A3 A3 A3 A3 A3 . . . . . .'
                '. A3 A3 A3 A3 A3 . . . . . .';
        }

        &.layout_3 {
            grid-template-areas:
                '. . . . . . A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                '. A3 A3 A3 A3 A3 A2 A2 A2 A2 A2 A2'
                '. A3 A3 A3 A3 A3 A2 A2 A2 A2 A2 A2'
                '. A3 A3 A3 A3 A3 A2 A2 A2 A2 A2 A2'
                '. A3 A3 A3 A3 A3 A2 A2 A2 A2 A2 A2'
                '. . . . . . A2 A2 A2 A2 A2 A2';
        }

        &.layout_4 {
            grid-template-areas:
                'A1 A1 A1 A1 A1 A1 . . . . . .'
                'A1 A1 A1 A1 A1 A1 . . . . . .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .';
        }

        &.layout_5 {
            grid-template-areas:
                'A1 A1 A1 A1 A1 A1 . . . . . .'
                'A1 A1 A1 A1 A1 A1 . . . . . .'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                'A1 A1 A1 A1 A1 A1 A2 A2 A2 A2 A2 A2'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .'
                '. . A3 A3 A3 A3 A3 A3 A3 A3 . .';
        }

        &.layout_6 {
            grid-template-areas:
                '. . . . . . A3 A3 A3 A3 A3 A3'
                '. . . . . . A3 A3 A3 A3 A3 A3'
                'A1 A1 A1 A1 A1 A1 A3 A3 A3 A3 A3 A3'
                'A1 A1 A1 A1 A1 A1 A3 A3 A3 A3 A3 A3'
                'A1 A1 A1 A1 A1 A1 A3 A3 A3 A3 A3 A3'
                'A1 A1 A1 A1 A1 A1 A4 A4 A4 A4 A4 A4'
                'A1 A1 A1 A1 A1 A1 A4 A4 A4 A4 A4 A4'
                'A1 A1 A1 A1 A1 A1 A4 A4 A4 A4 A4 A4'
                'A2 A2 A2 A2 A2 A2 A4 A4 A4 A4 A4 A4'
                'A2 A2 A2 A2 A2 A2 A4 A4 A4 A4 A4 A4'
                'A2 A2 A2 A2 A2 A2 . . . . . .'
                'A2 A2 A2 A2 A2 A2 . . . . . .';
        }
    }

    &-item {
        .image {
            overflow: hidden;

            img {
                @include image-fit(cover);
                object-position: top;
                transition: transform $transition-length-xl ease-in-out;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        &.item-layout_1-1,
        &.item-layout_2-1,
        &.item-layout_3-1,
        &.item-layout_4-1,
        &.item-layout_5-1,
        &.item-layout_6-1 {
            grid-area: A1;
        }

        &.item-layout_1-2,
        &.item-layout_2-2,
        &.item-layout_3-2,
        &.item-layout_4-2,
        &.item-layout_5-2,
        &.item-layout_6-2 {
            grid-area: A2;
        }

        &.item-layout_1-3,
        &.item-layout_2-3,
        &.item-layout_3-3,
        &.item-layout_4-3,
        &.item-layout_5-3,
        &.item-layout_6-3 {
            grid-area: A3;
        }

        &.item-layout_1-4,
        &.item-layout_2-4,
        &.item-layout_3-4,
        &.item-layout_4-4,
        &.item-layout_6-4 {
            grid-area: A4;
        }
    }

    @include media-breakpoint-down(md) {
        &--big {
            .image-gallery-content {
                height: 500px;
            }
        }

        &--small {
            .image-gallery-content {
                height: 500px;
            }
        }

        &-item {
            .image {
                img {
                    &:hover {
                        transform: none;
                    }
                }
            }
        }
    }
}
