.header {
    background-color: transparent;
    transition: background-color $transition-length ease-in-out;

    &-logo {
        transform-origin: left;
        transition: transform $transition-length ease-in-out;
    }

    &.scrolled {
        background-color: $tertiary;

        #main-nav {
            padding-top: map-get($map: $spacers, $key: md);

            .dropdown-menu {
                background-color: $tertiary;
            }
        }
    }

    &.scrolled {
        .header-logo {
            transform: scale(0.8);
        }

        #main-nav .nav-link {
            --bs-nav-link-padding-y: #{map-get($map: $spacers, $key: md)};
        }
    }
}
