.content-banner {
    @include rfs(3.5rem, padding-block);

    &.bg-primary,
    &.bg-black {
        .headline-content,
        .text-content {
            color: $white;
        }
    }

    .link {
        @include rfs(2rem, margin-top);
    }
}
