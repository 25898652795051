.partner-banner {
    &-content {
        .brand-items {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: auto;
            align-items: center;
            @include rfs(5.9375rem, column-gap);
            @include rfs(3.125rem, row-gap);

            .brand-item {
                display: flex;
                justify-content: center;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
