.container {
    &-inner {
        // @include spacing(padding, x, map-get($map: $spacers, $key: 5x));

        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-fluid {
        padding: 0;

        > .container-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
