* {
    scroll-margin-top: 50px;
}

body {
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-font-variant-ligatures: none;
    -webkit-text-size-adjust: 100%;

    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main {
        flex-grow: 1;
        overflow-x: hidden;
    }
}

a[href^='mailto:'],
a[href^='tel:'] {
    text-decoration: none;
}

p {
    &:only-child,
    &:last-child {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

ul {
    padding-left: map-get($map: $spacers, $key: md);
}
