.footer-main {
    overflow-x: hidden;
    color: $white;
    background-color: $body-color;
    @include spacing(padding, top, map-get($map: $spacers, $key: 2x));
    @include spacing(padding, bottom, map-get($map: $spacers, $key: 2x));

    &-content {
        @include spacing(margin, top, map-get($map: $spacers, $key: 4x));
        @include spacing(margin, bottom, map-get($map: $spacers, $key: 4x));
    }

    &-meta {
        .company {
            display: flex;
            flex-direction: column;
            gap: map-get($map: $spacers, $key: xs);

            &-name {
                @include use-nav-link(md, $white);
                @include margin-bottom(8px);
            }

            &-contact {
                display: flex;
                flex-direction: column;
                gap: map-get($map: $spacers, $key: sm);
                @include margin-top(16px);

                a {
                    font-weight: 400;
                    color: $white;
                    white-space: nowrap;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        .footer-nav {
            &-headline {
                @include use-headline(sm, $white);
            }

            &-items {
                list-style: none;
                padding-left: 0;
                display: flex;
                flex-direction: column;
                gap: map-get($map: $spacers, $key: xs);
                margin-bottom: 0;
            }

            &-item a {
                color: $white;
                text-decoration: none;

                &:hover {
                    color: $primary;
                }
            }
        }

        .logo {
            display: flex;
            align-items: end;
            margin-left: auto;

            img {
                height: 100px;
            }
        }
    }

    &-copyright {
        &-logos {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: map-get($map: $spacers, $key: 2x);
        }

        &-nav {
            margin-top: map-get($map: $spacers, $key: 2x);
            padding-top: map-get($map: $spacers, $key: md);
            border-top: 1px solid $gray-200;

            .nav-imprint {
                display: flex;
                align-items: center;

                .copyright-message {
                    margin-bottom: 0;
                    padding-right: map-get($map: $spacers, $key: lg);
                    @include use-nav-link(sm, $white);
                    font-size: 0.8rem;
                }
            }

            .nav {
                margin-left: auto;

                .nav-item:last-child {
                    .nav-link {
                        padding-right: 0;
                    }
                }

                .nav-link {
                    --bs-nav-link-color: #{$white};
                    @include use-nav-link(sm, $white);
                    font-size: 0.8rem;

                    &:hover {
                        @include use-nav-link(sm, $primary);
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &-copyright {
            &-logos {
                justify-content: center;
                text-align: center;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-meta {
            align-items: center;
            text-align: center;

            .company {
                .company-name {
                    @include margin-bottom(0);
                }
            }

            .logo {
                justify-self: center;
                margin-left: 0;
            }
        }

        &-copyright {
            &-nav {
                justify-content: center;

                .nav-quicklinks {
                    display: none;
                }

                .nav-imprint {
                    flex-direction: column;

                    .copyright-message {
                        padding-right: 0;
                    }

                    .nav {
                        margin: auto;

                        .nav-item:last-child {
                            .nav-link {
                                padding-right: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
