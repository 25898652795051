.chat {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &-bubble {
        background-color: $white;
        max-width: 55%;
        border-radius: 0.9375rem;
        position: relative;
        @include rfs(1.5rem, padding);

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            position: absolute;
            top: 50%;
            left: 0;
            background-color: $white;
            transform: rotate(-90deg) translate(50%, -100%);
        }

        &:nth-child(even) {
            margin-left: auto;

            &::after {
                left: auto;
                right: 0;
                transform: rotate(90deg) translate(-50%, -100%);
            }
        }
    }

    &-name {
        color: $primary;
        font-weight: 600;
    }

    @include media-breakpoint-down(sm) {
        &-bubble {
            max-width: 70%;
        }
    }
}
