.card-slider {
    &-item {
        overflow: hidden;
        background-color: $white;
        display: flex;
        flex-direction: column;

        &-image {
            img {
                aspect-ratio: 470 / 420;
                object-fit: cover;
            }
        }

        &-content {
            flex: 1;
            padding: map-get($map: $spacers, $key: 2x);

            .headline .headline-content {
                @include use-headline(md);
                font-weight: 700;
                margin-bottom: map-get($map: $spacers, $key: sm);
            }

            p {
                @include use-body(md, $body-color);

                &:first-of-type {
                    font-weight: 500;
                }
            }
        }

        &-link {
            margin-top: map-get($map: $spacers, $key: md);
            padding: map-get($map: $spacers, $key: md);
            display: flex;
            justify-content: flex-end;
        }
    }
}
