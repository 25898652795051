.btn {
    --bs-btn-border-width: 1px;
    @include use-button-label(md);
    @include spacing(padding, y, $btn-padding-y);
    @include spacing(padding, x, $btn-padding-x);
}

// Variant solid
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @if $color == 'primary' {
            @include button-variant(
                $background: $primary,
                $color: $white,
                $hover-color: $white,
                $border: transparent,
                $hover-background: $primary-light,
                $hover-border: transparent,
                $active-background: $primary,
                $active-border: transparent,
                $active-color: $primary
            );
        } @else if $color == 'secondary' {
            @include button-variant(
                $background: $white,
                $color: $primary,
                $hover-color: $white,
                $border: transparent,
                $hover-background: transparent,
                $hover-border: $white,
                $active-background: $white,
                $active-border: transparent,
                $active-color: $primary
            );
        }
    }
}

// Variant outline
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant(
            $value,
            $color-hover: $btn-color,
            $active-background: $value,
            $active-border: $value,
            $active-color: $btn-color
        );
    }
}

.link-icon {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: 3.5rem;
    text-decoration: none;

    // Arrow
    &::after {
        content: '\f061';
        position: absolute;
        font-family: 'Font Awesome 5 Pro';
        transition:
            transform 0.15s ease-in-out,
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out;
        right: 12.5%;
    }

    &:hover {
        &::after {
            transform: translateX(5px);
        }
    }
}
